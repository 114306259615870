import React from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

import { resendUserInvitation } from "@/api/users";

import { useModal } from "@/context/modal/modal";

import GenericError from "@/components/ui/generic-error";

import type { User } from "@/types/users";

import {
    ButtonsWrapper,
    Description,
    Email,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
    Title,
} from "../../modal-contents.styles";

import ConfirmationModalContent from "../confirmation/confirmation-modal-content";

interface ResendInviteModalContentProps {
    user: User;
}

const ResendInviteModalContent = ({ user }: ResendInviteModalContentProps) => {
    const { closeModal, openModal } = useModal();

    const queryClient = useQueryClient();

    const userId = user.userId;

    const mutation = useMutation(resendUserInvitation, {
        onSuccess: () => {
            const queryKey = ["getUsers", null, null];

            queryClient.invalidateQueries(queryKey); // Invalidates cached data associated with the "getUsers" query key

            openModal({
                content: (
                    <ConfirmationModalContent
                        title="users:users-table:modal-contents:resend-invite:confirmation-modal:title"
                        description="users:users-table:modal-contents:resend-invite:confirmation-modal:description"
                    />
                ),
            });
        },
        onError: () => {
            openModal({ content: <GenericError /> });
        },
    });

    const handleResendInviteButton = () => {
        mutation.mutate(userId); //PUT request to resend invitation -> /User/invitation/${userId} { userId: string}
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id="users:users-table:modal-contents:resend-invite:title" />
            </Title>
            <Description>
                <FormattedMessage id="users:users-table:modal-contents:resend-invite:description" />
                <Email>{`\u00A0${user.email}?`}</Email>
            </Description>
            <ButtonsWrapper>
                <StyledConfirmButton onClick={handleResendInviteButton} fullWidth>
                    <FormattedMessage id="users:users-table:modal-contents:resend-invite:primary-button" />
                </StyledConfirmButton>
                <StyledCancelButton onClick={closeModal} fullWidth>
                    <FormattedMessage id="users:users-table:modal-contents:secondary-button" />
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default ResendInviteModalContent;
