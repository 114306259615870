import Chart from "react-apexcharts";

import styled, { css } from "styled-components";

import base from "@/themes/base/base";

export const Container = styled.div`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem 1.5rem 0;
    gap: 0.5rem;

    svg {
        pointer-events: all;
    }
`;

export const tooltipStyles = css`
    border-radius: 2px;
    padding: 8px 8px;
    background-color: ${base.colors.neutral.grey[600]};
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
        color: #eee;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.14px;

        sup {
            font-size: 0.6em;
        }
    }

    span.no-icon {
        margin-top: 5px;
        margin-left: 26px;
    }

    span.hidden {
        display: none;
    }

    img {
        vertical-align: middle;
        margin: 0 5px 4px 0;
    }

    img.timeline {
        margin: 0px 4px 2px 0;
    }
`;

export const CustomAreaChart = styled(Chart)`
    margin-bottom: 7px;
    .apexcharts-tooltip {
        border: none !important;
        box-shadow: none !important;
    }

    .custom-tooltip {
        ${tooltipStyles}
    }
`;

export const ScatterChart = styled(Chart)`
    margin-bottom: -30px;

    .apexcharts-gridline:first-child {
        display: none;
    }

    .apexcharts-tooltip {
        border: none !important;
        box-shadow: none !important;
    }

    .custom-tooltip {
        ${tooltipStyles}
    }
`;
