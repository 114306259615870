import React, { useEffect, useRef, useState } from "react";
import type { ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

import { addTrainee, addTraineeBulk } from "@/api/trainee";

import { useModal } from "@/context/modal/modal";

import { AddUsersEmailErrorAPIResponse } from "@/pages/users/components/options-bar/options-bar.types";

import GenericError from "@/components/ui/generic-error";

import {
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    StyledConfirmButton,
    StyledInput,
    Title,
} from "../../modal-contents.styles";

import AddUserNotSuccessfulModalContent from "../add-user-not-successful/add-user-not-successful-modal-content";
import ConfirmationModalContent from "../confirmation/confirmation-modal-content";

export interface AddUserErrorAPIResponse {
    propertyName: string;
    errorMessage: string;
    attemptedValue: string;
    errorCode: string;
}

export interface NotCreatedUsersResponse {
    emailAddress: string;
    errorMessage: string;
    errorCode: string;
}
export interface AddMultipleUsersSuccessfulAPIResponse {
    createdUsers: string[];
    notCreatedUsers: NotCreatedUsersResponse[];
}

interface AddUserModalContentProps {
    title: string;
    description: string;
    setShouldUpdateUsersList: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const AddUserModalContent = ({
    title,
    description,
    setShouldUpdateUsersList,
}: AddUserModalContentProps) => {
    const { openModal, setMinHeight, setWidth } = useModal();

    const queryClient = useQueryClient();

    const [inputValue, setInputValue] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    //this useEffect is for the input field to focus when the modal is open
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const mutationForSingleUser = useMutation(addTrainee, {
        onSuccess: () => {
            const queryKey = ["getUsers", null, null];

            queryClient.invalidateQueries(queryKey); // Invalidates cached data associated with the "getUsers" query key

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(true);
            }

            openModal({
                content: (
                    <ConfirmationModalContent
                        title="users:users-table:modal-contents:add-user:confirmation-modal:title"
                        description="users:users-table:modal-contents:add-user:confirmation-modal:description"
                        isAddUserConfirmationModal
                    />
                ),
            });

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(false);
            }
        },
        onError: (error: any) => {
            const internalServerError = error.response === undefined;

            const existingEmailError =
                error.response.status === 400 &&
                error.response.data[0].errorCode ===
                    AddUsersEmailErrorAPIResponse.EmailAlreadyExists;

            const invalidEmailError =
                error.response.status === 400 &&
                error.response.data[0].errorCode === AddUsersEmailErrorAPIResponse.InvalidEmail;

            if (internalServerError) {
                openModal({ content: <GenericError /> });
            }

            if (existingEmailError) {
                openModal({
                    content: (
                        <AddUserNotSuccessfulModalContent
                            title="users:users-table:modal-contents:add-user-not-successful:title"
                            description="users:users-table:modal-contents:add-user-not-successful:description:repeated-email"
                            addSingleUserErrorAPIResponse={error.response.data}
                        />
                    ),
                });
            } else if (invalidEmailError) {
                openModal({
                    content: (
                        <AddUserNotSuccessfulModalContent
                            title="users:users-table:modal-contents:add-user-not-successful:title"
                            description="users:users-table:modal-contents:add-user-not-successful:description:invalid-email"
                        />
                    ),
                });
            } else {
                openModal({ content: <GenericError /> });
            }
        },
    });

    const mutationForMultipleUsers = useMutation(addTraineeBulk, {
        onSuccess: (response: AddMultipleUsersSuccessfulAPIResponse) => {
            const queryKey = ["getUsers", null, null];

            queryClient.invalidateQueries(queryKey); // Invalidates cached data associated with the "getUsers" query key

            const successInCreatingAllUsers =
                response.createdUsers.length > 0 && response.notCreatedUsers.length === 0;
            const onlySomeUsersAdded =
                response.createdUsers.length > 0 && response.notCreatedUsers.length > 0;
            const noUsersAdded = response.createdUsers.length === 0;

            //if no users are added, there's no need to updateUsersList
            if (noUsersAdded) {
                setWidth("540px");
                setMinHeight("352px");

                return openModal({
                    content: (
                        <AddUserNotSuccessfulModalContent
                            title="users:users-table:modal-contents:add-user-not-successful:title"
                            description="users:users-table:modal-contents:add-user-not-successful:description:repeated-email"
                            addMultipleUsersSuccessfulAPIResponse={response}
                        />
                    ),
                });
            }

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(true);
            }

            if (successInCreatingAllUsers) {
                openModal({
                    content: (
                        <ConfirmationModalContent
                            title="users:users-table:modal-contents:add-user:confirmation-modal:title"
                            description="users:users-table:modal-contents:add-user:confirmation-modal:description"
                            isAddUserConfirmationModal
                        />
                    ),
                });
            }

            if (onlySomeUsersAdded) {
                setWidth("540px");
                setMinHeight("352px");
                openModal({
                    content: (
                        <ConfirmationModalContent
                            title="users:users-table:modal-contents:add-user:confirmation-modal:not-all-users-added:title"
                            description="users:users-table:modal-contents:add-user:confirmation-modal:description"
                            isAddUserConfirmationModal
                            addMultipleUsersSuccessfulAPIResponse={response}
                        />
                    ),
                });
            }

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(false);
            }
        },
        onError: (error: any) => {
            const internalServerError = error.response === undefined;

            const invalidEmailError =
                error.response.status === 400 &&
                error.response.data[0].errorCode === AddUsersEmailErrorAPIResponse.InvalidEmail;

            const maximumSizeReachedError =
                error.response.status === 400 &&
                error.response.data[0].errorCode === AddUsersEmailErrorAPIResponse.MaxiSizeReached;

            const duplicateEmailsError =
                error.response.status === 400 &&
                error.response.data[0].errorCode === AddUsersEmailErrorAPIResponse.DuplicateEmails;

            if (internalServerError) {
                openModal({ content: <GenericError /> });
            } else if (invalidEmailError) {
                openModal({
                    content: (
                        <AddUserNotSuccessfulModalContent
                            title="users:users-table:modal-contents:add-user-not-successful:title"
                            description="users:users-table:modal-contents:add-user-not-successful:description:invalid-email"
                        />
                    ),
                });
            } else if (maximumSizeReachedError) {
                openModal({
                    content: (
                        <AddUserNotSuccessfulModalContent
                            title="users:users-table:modal-contents:add-user-not-successful:title"
                            description="users:users-table:modal-contents:add-user-not-successful:description:max-size-reached"
                        />
                    ),
                });
            } else if (duplicateEmailsError) {
                openModal({
                    content: (
                        <AddUserNotSuccessfulModalContent
                            title="users:users-table:modal-contents:add-user-not-successful:title"
                            description="users:users-table:modal-contents:add-user-not-successful:description:duplicate-emails"
                        />
                    ),
                });
            } else {
                openModal({ content: <GenericError /> });
            }
        },
    });

    //stores what user is currently writing
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setInputValue(event.target.value);
    };

    //stores submitted value
    const handleSendInviteButton = (
        event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>,
    ) => {
        event.preventDefault();
        setLoading(true);

        //CHECK IF IS SINGLE OR MULTIPLE USERS
        const arrayFromInputValue = inputValue?.split(",").map((email) => email.trim()) ?? [];
        const singleUser = arrayFromInputValue?.length === 1;
        const multipleUsers = arrayFromInputValue?.length > 1;

        if (singleUser) {
            mutationForSingleUser.mutate(inputValue, {
                onSettled: () => setLoading(false), //logic for the button only
            }); // POST request to addTrainee -> /trainee API { emailAddress: inputValue }
        } else if (multipleUsers) {
            mutationForMultipleUsers.mutate(arrayFromInputValue, {
                onSettled: () => setLoading(false), //logic for the button only
            }); // POST request to addTraineeBulk -> /trainee API { emailAddresses: addresses }
        }

        setInputValue("");
    };

    const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.stopPropagation(); //stops button from making api request
            handleSendInviteButton(event);
        }
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id={title} />
            </Title>
            <Description>
                <FormattedMessage id={description} />
            </Description>
            <ButtonsWrapper>
                <StyledInput
                    ref={inputRef}
                    placeholder="Email"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKey}
                />
                <StyledConfirmButton
                    onClick={handleSendInviteButton}
                    disabled={!inputValue || loading}
                    fullWidth
                >
                    <FormattedMessage id="users:users-table:modal-contents:add-user:primary-button" />
                </StyledConfirmButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default AddUserModalContent;
