import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import { ProgressProvider } from "./progress-provider";

import { Score, ScoreWrapper, TotalScore } from "./progress-bar.styles";

type ProgressBarProps = {
    width?: number;
    color?: string;
    valueEnd: number;
    scoreFontSize: string;
    totalScoreFontSize?: string;
};

export const ProgressBar = ({
    width,
    valueEnd,
    color = "#EE7127",
    scoreFontSize,
    totalScoreFontSize,
}: ProgressBarProps) => {
    return (
        <ProgressProvider valueStart={0} valueEnd={valueEnd}>
            {(value) => (
                <div style={{ width, alignSelf: "center" }}>
                    <CircularProgressbarWithChildren
                        value={value}
                        strokeWidth={4}
                        styles={buildStyles({
                            pathColor: color,
                            trailColor: "#383838",
                            pathTransitionDuration: 1,
                        })}
                    >
                        <ScoreWrapper>
                            <Score $fontSize={scoreFontSize}>{value}</Score>
                            {totalScoreFontSize && (
                                <TotalScore $fontSize={totalScoreFontSize}>/100</TotalScore>
                            )}
                        </ScoreWrapper>
                    </CircularProgressbarWithChildren>
                </div>
            )}
        </ProgressProvider>
    );
};
