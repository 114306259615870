export const data = [
    {
        code: "circuit-top-stats",
        name: "Take-Off",
        description: "Tooltip description",
        order: 0,
        dataSets: [
            {
                title: "AOA",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 444,
                        order: 0,
                        description: "Min",
                    },
                    {
                        result: 666,
                        order: 1,
                        description: "Avg",
                    },
                    {
                        result: 888,
                        order: 2,
                        description: "Max",
                    },
                ],
            },
            {
                title: "Degrees Nose Up",
                subTitle: "(in HUD)",
                order: 1,
                values: [
                    {
                        result: 74,
                        order: 0,
                        description: null,
                    },
                ],
            },
            {
                title: "Speed",
                subTitle: "(before upwind turn)",
                order: 2,
                values: [
                    {
                        result: 10,
                        order: 0,
                        description: "Min",
                    },
                    {
                        result: 35,
                        order: 1,
                        description: "Avg",
                    },
                    {
                        result: 60,
                        order: 1,
                        description: "Max",
                    },
                ],
            },
        ],
    },
    {
        code: "circuit-top-stats",
        name: "Upwind Turn",
        description: "Tooltip description",
        order: 1,
        dataSets: [
            {
                title: "Lookout",
                subTitle: null,
                order: 0,
                values: [
                    {
                        result: 999,
                        order: 0,
                        description: null,
                    },
                ],
            },
            {
                title: "AOA",
                subTitle: null,
                order: 1,
                values: [
                    {
                        result: 222,
                        order: 0,
                        description: "Min",
                    },
                    {
                        result: 555,
                        order: 1,
                        description: "Avg",
                    },
                    {
                        result: 888,
                        order: 2,
                        description: "Max",
                    },
                ],
            },
            {
                title: "AOB",
                subTitle: null,
                order: 2,
                values: [
                    {
                        result: 333,
                        order: 0,
                        description: "Min",
                    },
                    {
                        result: 555,
                        order: 1,
                        description: "Avg",
                    },
                    {
                        result: 999,
                        order: 2,
                        description: "Max",
                    },
                ],
            },
            {
                title: "Speed",
                subTitle: null,
                order: 2,
                values: [
                    {
                        result: 10,
                        order: 0,
                        description: "Min",
                    },
                    {
                        result: 35,
                        order: 1,
                        description: "Avg",
                    },
                    {
                        result: 60,
                        order: 1,
                        description: "Max",
                    },
                ],
            },
        ],
    },
];
