import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { useSessionDetails } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { Client } from "@/enums/clients";

import { Title } from "../../session-detail.styles";

import {
    ColoredOverlay,
    Overlay,
    SectionPercentage,
    DataContainer,
    LeftSection,
    CenterTopSection,
    CenterBottomSection,
    RightSection,
    EyeTrackingImage,
    CenterSection,
    SectionText,
    Section,
} from "./styles";

const getGazeEyeValue = (gazeEyeData: any, key: string) => {
    return gazeEyeData?.datasets[0]?.[key];
};

export const EyeTracking = () => {
    const { combinedChartsData, isFetchingCombinedCharts, customerSettingsData } =
        useSessionDetails();

    const customerName = customerSettingsData?.customerName;

    // Filter the data to get the object with the code 'GAZE_EYE'
    const gazeEyeData = combinedChartsData?.find((item: any) => item.code === "GAZE_EYE");

    const isInColumn = useMemo(() => {
        if (customerName == Client.VRAIDT) {
            return true;
        }
    }, [customerName]);

    if (gazeEyeData?.datasets.length === 0) return null;

    if (isFetchingCombinedCharts)
        return <ContentLoader height={isInColumn ? "21.1875rem" : "26rem"} />;

    return (
        <Section.Container>
            <Section.LandingScore>
                <Title>
                    <FormattedMessage id={"session-detail:gaze-eye-tracking"} />
                    <Tooltip simpleText={gazeEyeData?.description} />
                </Title>
                <DataContainer isInColumn={isInColumn}>
                    <EyeTrackingImage src="/assets/eye-tracking-bg.png" />
                    <ColoredOverlay />
                    <Overlay>
                        <LeftSection>
                            <div>
                                <SectionText>
                                    <FormattedMessage id={"session-detail:nearside"} />
                                </SectionText>
                                <SectionPercentage>
                                    {getGazeEyeValue(gazeEyeData, "LEFT_MIRROR")}%
                                </SectionPercentage>
                            </div>
                        </LeftSection>
                        <CenterSection>
                            <CenterTopSection>
                                <div>
                                    <SectionText>
                                        <FormattedMessage id={"session-detail:far-distance"} />
                                    </SectionText>
                                    <SectionPercentage>
                                        {getGazeEyeValue(gazeEyeData, "TOP_MIRROR")}%
                                    </SectionPercentage>
                                </div>
                            </CenterTopSection>
                            <CenterBottomSection>
                                <div>
                                    <SectionText>
                                        <FormattedMessage id={"session-detail:near-distance"} />
                                    </SectionText>
                                    <SectionPercentage>
                                        {getGazeEyeValue(gazeEyeData, "BOTTOM_MIRROR")}%
                                    </SectionPercentage>
                                </div>
                            </CenterBottomSection>
                        </CenterSection>
                        <RightSection>
                            <div>
                                <SectionText>
                                    <FormattedMessage id={"session-detail:offside"} />
                                </SectionText>
                                <SectionPercentage>
                                    {getGazeEyeValue(gazeEyeData, "RIGHT_MIRROR")}%
                                </SectionPercentage>
                            </div>
                        </RightSection>
                    </Overlay>
                </DataContainer>
            </Section.LandingScore>
        </Section.Container>
    );
};
