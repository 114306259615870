import { useCallback, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import styled from "styled-components";

import Checkbox from "@/components/filters/checkbox";
import { ArrowDownIcon } from "@/components/icons";

import {
    Container,
    Item,
    List,
    ListContainer,
    Toggle,
    TraineesPopUp,
    TextSpan,
} from "./filters.styles";

export type Option = {
    id: string;
    label: string;
};

type Props = {
    handleOnClick: (name: string | null) => void;
    onSelectionChange: (selected: Option[]) => void;
    options: Option[];
    style?: React.CSSProperties;
    title: string; // New prop for title
    icon: React.ReactElement; // New prop for icon
};

const SelectAllContainer = styled.div<{ isChecked: boolean }>`
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    height: 2.5rem;
    box-sizing: border-box;
    position: relative;

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

        .styled-checkbox {
            border-color: ${({ theme }) => theme.colors.primary.orange[500]};
            background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

            &.checked {
                border-color: ${({ theme }) => theme.colors.primary.orange[300]};
                background-color: ${({ theme }) => theme.colors.primary.orange[300]};
            }
        }
    }
`;

const ToggleButton = styled(Toggle)<{ isOpen: boolean }>`
    max-width: 100%;
    width: 100%;
    border: 1px solid
        ${({ isOpen, theme }) =>
            isOpen ? theme.colors.primary.orange[500] : theme.colors.neutral.grey[300]};

    &:hover {
        border-color: ${({ theme }) => theme.colors.primary.orange[500]};
    }
`;

const SelectedCountSpan = styled.span`
    border-radius: 32px;
    background-color: ${({ theme }) => theme.colors.primary.orange[500]};
    padding: 0px 8px;
    color: ${({ theme }) => theme.colors.neutral.grey[900]};
`;

const ToggleButtonContent = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
`;

const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
    width: 2rem;
    display: flex;
    justify-content: flex-end;
    transform: ${({ isOpen }) => (isOpen ? "scaleY(-1)" : "scaleY(1)")};
    transition: transform 0.3s ease;
`;

const CheckboxContainer = styled.div`
    margin-right: 8px;
`;

const StyledItem = styled(Item)<{ isChecked: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: ${({ isChecked, theme }) =>
        isChecked ? theme.colors.neutral.grey[800] : "transparent"};

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

        .styled-checkbox {
            border-color: ${({ theme }) => theme.colors.primary.orange[500]};
            background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

            &.checked {
                border-color: ${({ theme }) => theme.colors.primary.orange[300]};
                background-color: ${({ theme }) => theme.colors.primary.orange[300]};
            }
        }
    }
`;

const SeparatorLine = styled.div`
    width: 80%;
    background-color: #121212;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`;

export const MultiSelectDropdown = ({
    handleOnClick,
    onSelectionChange,
    options,
    title,
    icon,
    ...props
}: Props) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleList = useCallback(() => {
        setIsOpen((currentIsOpen) => !currentIsOpen);
    }, []);

    const handleOptionToggle = useCallback(
        (option: Option) => {
            setSelectedOptions((prevSelected) => {
                const isSelected = prevSelected.some(({ id }) => id === option.id);
                const newSelected = isSelected
                    ? prevSelected.filter(({ id }) => id !== option.id)
                    : [...prevSelected, option];
                onSelectionChange(newSelected);

                return newSelected;
            });
        },
        [onSelectionChange],
    );

    const handleSelectAll = useCallback(() => {
        const allSelected = options.length === selectedOptions.length;
        const newSelected = allSelected ? [] : options;
        setSelectedOptions(newSelected);
        onSelectionChange(newSelected);
    }, [options, selectedOptions, onSelectionChange]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: () => {
            setIsOpen(false);
            handleOnClick(null);
        },
    });

    return (
        <Container ref={clickOutsideRef} {...props}>
            <ToggleButton isOpen={isOpen} onClick={handleToggleList}>
                <ToggleButtonContent>
                    {icon}
                    <span>{title}</span>
                    <SelectedCountSpan>{selectedOptions.length}</SelectedCountSpan>
                </ToggleButtonContent>
                <ArrowIconContainer isOpen={isOpen}>
                    <ArrowDownIcon />
                </ArrowIconContainer>
            </ToggleButton>
            {isOpen && (
                <TraineesPopUp width="100%">
                    <ListContainer>
                        <SelectAllContainer
                            isChecked={options.length === selectedOptions.length}
                            onClick={handleSelectAll}
                        >
                            <SeparatorLine />
                            <CheckboxContainer>
                                <Checkbox
                                    checked={options.length === selectedOptions.length}
                                    onChange={handleSelectAll}
                                />
                            </CheckboxContainer>
                            <TextSpan>Select All</TextSpan>
                        </SelectAllContainer>
                        <List>
                            {options.map((option) => (
                                <StyledItem
                                    key={option.id}
                                    isChecked={selectedOptions.some(({ id }) => id === option.id)}
                                    onClick={() => handleOptionToggle(option)}
                                >
                                    <CheckboxContainer>
                                        <Checkbox
                                            checked={selectedOptions.some(
                                                ({ id }) => id === option.id,
                                            )}
                                            onChange={() => handleOptionToggle(option)}
                                        />
                                    </CheckboxContainer>
                                    <TextSpan>{option.label}</TextSpan>
                                </StyledItem>
                            ))}
                        </List>
                    </ListContainer>
                </TraineesPopUp>
            )}
        </Container>
    );
};
