import type { IconProps } from "@/types/icon";

const CloseIcon12 = ({
    width = 12,
    height = 12,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 12 12`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...remainingProps}
    >
        <path
            d="M1 11L11 1M1 1L11 11"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CloseIcon12;
