import { FormattedMessage } from "react-intl";

import { useModal } from "@/context/modal/modal";

import { TertiaryButton } from "@/pages/shared/shared.styles";

import base from "@/themes/base/base";

import { ChevronRightSquareCornersIcon24, TooltipIcon24 } from "@/components/icons";
import PageHeader from "@/components/ui/page-header/page-header";
import { ProgressBar } from "@/components/ui/progress-bar/progress-bar";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { useSessionInfo } from "../../hooks/useSessionInfo";
import { Title, SubTitle } from "../../session-detail.styles";

import LinearProgressCircuit from "./components/linear-progress-circuit";

import { circuitScore } from "./mock-data/data";

import {
    SubtitleAndTooltipWrapper,
    ProgressBarAndSubtitleWrapper,
    ScoringWrapper,
    ScoringAndCircuitWrapper,
    CircuitScoreWrapper,
    Divider,
    LinearProgressAndButtonWrapper,
    LinearProgressWrapper,
    DividerWrapper,
    ButtonsWrapper,
} from "./scoring.styles";

export const CircuitScoring = () => {
    const { openModal, setWidth, setHeight } = useModal();
    const { getSessionSubtitleDetails, logoUrl } = useSessionInfo();

    const circuitScoreData: any = circuitScore.filter(
        (dataset: any) => dataset.chartType === "ProgressBar",
    )[0].datasets[0];

    const linearProgressBarData: any = circuitScore.filter(
        (dataset: any) => dataset.chartType === "LinearProgressBar",
    )[0].datasets;

    const handleCircuitsReportButtonClick = () => {
        setWidth("76.6%");
        setHeight("87.4%");

        openModal({
            content: (
                <>
                    <PageHeader
                        title="Circuit 1 by default - to change"
                        logoUrl={logoUrl}
                        subtitleDetailText={getSessionSubtitleDetails}
                    />
                </>
            ),
            backgroundColor: `${base.colors.neutral.grey[900]}`,
            borderRadius: "0.25rem",
        });
    };

    const handleIndividualCircuitOpenModal = (circuit: any) => {
        setWidth("76.6%");
        setHeight("87.4%");

        openModal({
            content: (
                <>
                    <PageHeader
                        title={circuit.label}
                        logoUrl={logoUrl}
                        subtitleDetailText={getSessionSubtitleDetails}
                    />
                    <CircuitScoreWrapper>
                        <ProgressBarAndSubtitleWrapper>
                            <ProgressBar
                                width={174}
                                valueEnd={Math.round(circuitScoreData?.score)}
                                scoreFontSize="3rem"
                                totalScoreFontSize="1.5rem"
                            />

                            <SubtitleAndTooltipWrapper>
                                <SubTitle>{circuitScoreData?.legend}</SubTitle>
                                <Tooltip simpleText={circuitScoreData?.description} />
                            </SubtitleAndTooltipWrapper>
                        </ProgressBarAndSubtitleWrapper>
                    </CircuitScoreWrapper>
                </>
            ),
            backgroundColor: `${base.colors.neutral.grey[900]}`,
            borderRadius: "0.25rem",
        });
    };

    return (
        <ScoringWrapper>
            <Title>
                {circuitScoreData?.scoreTitle}
                <Tooltip formattedMessage="session-detail:circuit-scoring-tooltip" />
            </Title>

            <ScoringAndCircuitWrapper>
                <CircuitScoreWrapper>
                    <ProgressBarAndSubtitleWrapper>
                        <ProgressBar
                            width={174}
                            valueEnd={Math.round(circuitScoreData?.score)}
                            scoreFontSize="3rem"
                            totalScoreFontSize="1.5rem"
                        />

                        <SubtitleAndTooltipWrapper>
                            <SubTitle>{circuitScoreData?.legend}</SubTitle>
                            <Tooltip simpleText={circuitScoreData?.description} />
                        </SubtitleAndTooltipWrapper>
                    </ProgressBarAndSubtitleWrapper>
                </CircuitScoreWrapper>

                <DividerWrapper>
                    <Divider />
                </DividerWrapper>

                <LinearProgressAndButtonWrapper>
                    <LinearProgressWrapper>
                        {linearProgressBarData.map((circuit: any) => (
                            <LinearProgressCircuit
                                circuit={circuit}
                                key={circuit.result}
                                onModalOpen={() => handleIndividualCircuitOpenModal(circuit)}
                            />
                        ))}
                    </LinearProgressWrapper>
                    <ButtonsWrapper>
                        <TooltipIcon24 />
                        {/* this button opens modal for circuit 1 by default */}
                        <TertiaryButton onClick={handleCircuitsReportButtonClick}>
                            <FormattedMessage id="session-detail:circuit-scoring-button" />
                            <ChevronRightSquareCornersIcon24 />
                        </TertiaryButton>
                    </ButtonsWrapper>
                </LinearProgressAndButtonWrapper>
            </ScoringAndCircuitWrapper>
        </ScoringWrapper>
    );
};
