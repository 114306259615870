import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { getCustomerSettings } from "@/api/settings";

import { getTimeDifference } from "@/pages/session-detail/components/timeline-chart-with-firing/utils";

import { CalendarIcon24, CardArrowIcon64, TimeIcon24, UsersIcon24 } from "@/components/icons";

import { Client } from "@/enums/clients";

import type { SessionItemProps } from "./session-item.types";

import {
    BulletPoint,
    CardArrowContainer,
    DateTimeAndDurationContainer,
    SessionDetails,
    SessionIconContainer,
    SessionInfoContainer,
    SessionItemContainer,
    SessionTitle,
    DateAndTimeDiv,
    DurationDiv,
    DateDiv,
    TimeDiv,
    StyledImg,
    DetailItemTextSpan,
    SingleUserNameAndIconDiv,
    IndividualUserDiv,
    MultipleUsersNamesAndIconDiv,
} from "./session-item.styles";

const SessionItem = ({ session, index }: SessionItemProps) => {
    const history = useHistory();

    const duration = (from: any, to: any) => {
        return getTimeDifference(new Date(from), new Date(to));
    };

    const { id, name, simulationName, logoUrl, userId } = session;
    const { from, to, scenarioInstanceId, simulationId, scenarioName } = session.sessions[0];
    const cardLink = `/sessions/session-analysis?traineeName=${name}&simulationName=${simulationName}&userId=${userId}&from=${from}&to=${to}&scenarioId=${simulationId}&simulationInstanceId=${id}&scenarioInstanceId=${scenarioInstanceId}&name=${scenarioName}&logoUrl=${logoUrl}`;
    const handleClick = (event: any) => {
        event.preventDefault();
        history.push(cardLink);

        if (session.users.length !== 0) {
            localStorage.setItem("users", JSON.stringify(session.users));
        }
    };

    const sessionDate = moment(session.sessions[0].from).format("DD/MM/YYYY");
    const fromTime = moment(session.sessions[0].from).format("HH:mm");

    const options = {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: true,
    };

    const { data: d } = useQuery([getCustomerSettings], getCustomerSettings, options);

    if (d?.customerName == Client.MLRS) {
        session.simulationName = "TCT - MLRS";
        session.sessions[0].scenarioName = "Scenario 01";
    }

    const singleUser = session.users.length === 0;

    return (
        <SessionItemContainer
            onClick={(event) => handleClick(event)}
            href={cardLink}
            data-test-id={`session-${index + 1}`}
        >
            <SessionIconContainer>
                {session.logoUrl && <StyledImg src={session.logoUrl} alt="Session Logo" />}
            </SessionIconContainer>

            <SessionInfoContainer>
                <SessionTitle>
                    <span>{session.simulationName}</span>
                    <BulletPoint className="bullet-point">&bull;</BulletPoint>
                    <span>{session.sessions[0].scenarioName}</span>
                </SessionTitle>
                <SessionDetails>
                    {singleUser ? (
                        <SingleUserNameAndIconDiv>
                            <UsersIcon24 fill="#999999" />
                            <DetailItemTextSpan>{session.name}</DetailItemTextSpan>
                        </SingleUserNameAndIconDiv>
                    ) : (
                        <MultipleUsersNamesAndIconDiv>
                            {session.users
                                .toString()
                                .split(",")
                                .map((user, index) => (
                                    <IndividualUserDiv key={index}>
                                        <UsersIcon24 fill="#999999" />
                                        <DetailItemTextSpan>
                                            {user.charAt(0).toUpperCase() +
                                                user
                                                    .slice(1)
                                                    .replace(/,/g, " - ")
                                                    .replace("=>", " - ")}
                                        </DetailItemTextSpan>
                                    </IndividualUserDiv>
                                ))}
                        </MultipleUsersNamesAndIconDiv>
                    )}
                </SessionDetails>
                <DateTimeAndDurationContainer>
                    <DateAndTimeDiv>
                        <DateDiv>
                            <CalendarIcon24 fill="#999999" />
                            <DetailItemTextSpan>{sessionDate}</DetailItemTextSpan>
                        </DateDiv>

                        <TimeDiv>
                            <TimeIcon24 fill="#999999" />
                            <DetailItemTextSpan>{fromTime}</DetailItemTextSpan>
                        </TimeDiv>
                    </DateAndTimeDiv>

                    <DurationDiv>
                        <BulletPoint>&bull;</BulletPoint>
                        <DetailItemTextSpan>
                            {duration(session.sessions[0].from, session.sessions[0].to)}
                        </DetailItemTextSpan>
                    </DurationDiv>
                </DateTimeAndDurationContainer>
            </SessionInfoContainer>

            <CardArrowContainer className="chevron-container">
                <CardArrowIcon64 />
            </CardArrowContainer>
        </SessionItemContainer>
    );
};

export default SessionItem;
