import { useState } from "react";

import { useSessionDetails } from "@/context/session-details/session-details";

import { MultiSelectDropdown } from "@/components/filters/multi-select";
import { TargetIcon16 } from "@/components/icons";

import { Title } from "../../session-detail.styles";

import {
    Container,
    HeaderContainer,
    ScrollableTBody,
    StyledCell,
    StyledHead,
    StyledHeader,
    StyledRow,
    StyledTable,
} from "./styles";

export const FireMissions = () => {
    const { customChartsData } = useSessionDetails();

    const dropdown = [];
    const log: any[] = [];
    let filteredList: any[] = [];

    for (let i = 0; i < customChartsData.length; i++) {
        if (
            customChartsData[i].chartType === "ProgressBar" &&
            customChartsData[i].code === "session-fire-mission-table"
        ) {
            for (let y = 0; y < customChartsData[i].datasets.length; y++) {
                dropdown.push({
                    FireMissionNumber: customChartsData[i].datasets[y].FireMissionNumber,
                });
                log.push(customChartsData[i].datasets[y]);
            }
        }
    }

    const [filteredLog, setFilteredLog] = useState(log);

    const uniqueMissions = Array.from(
        new Set(dropdown.map((mission) => mission.FireMissionNumber)),
    ).map((id) => {
        return {
            label: `Mission ${id}`,
            id: id.toString(),
        };
    });

    type Option = {
        id: string;
        label: string;
    };

    const handleSelectionChange = (selectedItems: Option[]) => {
        filteredList = [];

        for (let z = 0; z < selectedItems.length; z++) {
            if (log.length === 1) {
                filteredList.push(log[0]);
            } else {
                const filteredData = log.filter(
                    (x) => x.FireMissionNumber === selectedItems[z].id.toString(),
                );
                filteredList.push(filteredData[0]);
            }
        }

        setFilteredLog(filteredList);
    };

    return (
        <Container>
            <HeaderContainer>
                <Title>Fire Missions</Title>
                <MultiSelectDropdown
                    options={uniqueMissions}
                    title="Fire Missions"
                    style={{ width: "230px" }}
                    icon={<TargetIcon16 />}
                    handleOnClick={() => {}}
                    onSelectionChange={(selectedItems) => handleSelectionChange(selectedItems)}
                />
            </HeaderContainer>
            <div style={{ overflowX: "auto", width: "100%" }}>
                <StyledTable style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    <StyledHead>
                        <tr>
                            <StyledHeader>FIRE MISSION</StyledHeader>
                            <StyledHeader>DISTANCE TRAVELLED</StyledHeader>
                            <StyledHeader>ROUNDS FIRED</StyledHeader>
                            <StyledHeader>PHASE 1 SCORE</StyledHeader>
                            <StyledHeader>PHASE 2 SCORE</StyledHeader>
                            <StyledHeader>PHASE 3 SCORE</StyledHeader>
                            <StyledHeader>PHASE 4 SCORE</StyledHeader>
                            <StyledHeader>OVERALL SCORE</StyledHeader>
                            <StyledHeader style={{ textAlign: "left" }}>COMMENTS</StyledHeader>
                        </tr>
                    </StyledHead>
                    <ScrollableTBody>
                        {filteredLog.map((item) => (
                            <StyledRow key={item.FireMissionNumber}>
                                <StyledCell>{item.FireMissionNumber}</StyledCell>
                                <StyledCell>{item.DistanceTravelled}</StyledCell>
                                <StyledCell>{item.RoundsFired}</StyledCell>
                                <StyledCell>{item.Phase1Score}</StyledCell>
                                <StyledCell>{item.Phase2Score}</StyledCell>
                                <StyledCell>{item.Phase3Score}</StyledCell>
                                <StyledCell>{item.Phase4Score}</StyledCell>
                                <StyledCell>{item.OverallScore}</StyledCell>
                                <StyledCell>{item.Comments}</StyledCell>
                            </StyledRow>
                        ))}
                    </ScrollableTBody>
                </StyledTable>
            </div>
        </Container>
    );
};
