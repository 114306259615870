import type { ChartDataSet, LookoutData, LookoutDataSet } from "@/types/timeline-chart";

import { ChartName } from "@/enums/session-details";

//To get the time difference in minutes and seconds
export const getTimeDifference = (startTime: Date, endTime: Date, _showMilliseconds = false) => {
    const difference = endTime.getTime() - startTime.getTime();
    const minutes = Math.floor(difference / (1000 * 60));
    const seconds = Math.floor((difference / 1000) % 60);

    const timeDifference = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;

    return timeDifference;
};

/* When area chart toggle is turned off, we still need to show the annotation.
 We can create it by showing empty chart, this function will provide the data for it */
export const createEmptyData = (airtimeData: ChartDataSet[]): [Date, any][] =>
    airtimeData.map((dataSet) => [new Date(dataSet.DateTime), null]);

//convert the data from API to APEX format
export const convertDataToApex = (data: ChartDataSet[]): [Date, number | null][] =>
    data.map((dataSet) => [
        new Date(dataSet.DateTime),
        dataSet.Data === null ? null : Math.trunc(dataSet.Data),
    ]);

//create dataSets for Yaw
export const createYawData = (data: ChartDataSet[]) =>
    data.map((dataSet) => [new Date(dataSet.DateTime), dataSet?.Yaw]);

export const getMinAxis = (axisData: ChartDataSet[]) => {
    if (axisData?.[0]?.DateTime) {
        return new Date(axisData[0].DateTime).getTime();
    }

    return 0;
};

export const getMaxAxis = (axisData: ChartDataSet[]) => {
    if (axisData?.[axisData.length - 1]?.DateTime) {
        return new Date(axisData[axisData.length - 1].DateTime).getTime();
    }

    return 0;
};

export const getMaxValue = (axisData: ChartDataSet[]) => {
    const data = axisData?.map((data: ChartDataSet) => data.Data);

    return Math.max(...data);
};

export const removeOverlappingRecords = (dataset: LookoutDataSet[]) => {
    // sort data by startTime
    dataset.sort((a, b) => new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime());

    const similarDataSets = dataset.reduce(
        (acc: { [k: string]: LookoutDataSet }, record: LookoutDataSet) => {
            const startTimeWithoutMillis = record.StartTime.split(".")[0];
            const startTime = new Date(startTimeWithoutMillis).getTime();

            const startTimeMinus1second = startTime - 1000;
            const startTimeMinus2seconds = startTime - 2000;

            if (!acc[startTime] && !(acc[startTimeMinus1second] || acc[startTimeMinus2seconds])) {
                acc[startTime] = record;
            } else {
                if (acc[startTime]) {
                    acc[startTime].EndTime = record.EndTime;
                }
            }

            return acc;
        },
        {},
    );

    return Object.values(similarDataSets);
};

export const transformAnnotationData = (data: LookoutData[]) => {
    return data?.map((lookoutData) => {
        // Remove overlapping records for blindspotcheck data
        if (lookoutData.name === ChartName.BLINDSPOTCHECK) {
            lookoutData.data = removeOverlappingRecords(lookoutData.data);
        }

        return lookoutData;
    });
};
