import React, { useContext, useState, createContext } from "react";
import type { ReactNode } from "react";

import base from "@/themes/base/base";

interface ModalContextType {
    isModalOpen: boolean;
    openModal: ({ content, backgroundColor, borderRadius }: OpenModalProps) => void;
    closeModal: () => void;
    modalContent: ReactNode | null;
    width: string;
    height: string;
    setWidth: (width: string) => void;
    setHeight: (height: string) => void;
    minWidth: string;
    minHeight: string;
    setMinWidth: (width: string) => void;
    setMinHeight: (height: string) => void;
    backgroundColor: string | undefined;
    borderRadius: string | undefined;
}

interface OpenModalProps {
    content: ReactNode;
    backgroundColor?: string;
    borderRadius?: string;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [width, setWidth] = useState<string>("1032px");
    const [height, setHeight] = useState<string>("581px");
    const [minHeight, setMinHeight] = useState<string>("");
    const [minWidth, setMinWidth] = useState<string>("");
    const [backgroundColor, setBackgroundColor] = useState(`${base.colors.neutral.grey[800]}`);
    const [borderRadius, setBorderRadius] = useState("0.5rem");

    const openModal = ({ content, backgroundColor, borderRadius }: OpenModalProps) => {
        setBackgroundColor(backgroundColor || base.colors.neutral.grey[800]);
        setBorderRadius(borderRadius || "0.5rem");
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                modalContent,
                width,
                height,
                setWidth,
                setHeight,
                minWidth,
                minHeight,
                setMinWidth,
                setMinHeight,
                backgroundColor,
                borderRadius,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalContextType => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("useModal hook must be used within a ModalProvider");
    }

    return context;
};
