import { useEffect, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";

import type L from "leaflet";

// Update the props to accept an object with coordinates, zoom level, and isPlaying
const MapEffect = ({
    centerPosition,
    play,
    pause,
    isPlaying,
}: {
    centerPosition: { coords: L.LatLng; zoom?: number };
    play: () => void;
    pause: () => void;
    isPlaying: boolean;
}) => {
    const map = useMap();
    const [manualZoom, setManualZoom] = useState<number | undefined>(undefined);
    const [localIsPlaying, setIsPlaying] = useState(isPlaying);

    useMapEvents({
        zoomstart: () => {
            setIsPlaying(isPlaying);
            pause();
        },
        zoomend: () => {
            setManualZoom(map.getZoom());

            if (localIsPlaying) {
                play();
                setIsPlaying(false);
            }
        },
    });

    useEffect(() => {
        if (map && centerPosition.coords != null) {
            // Use the object's properties to set the view
            map.setView(centerPosition.coords, manualZoom || centerPosition.zoom || map.getZoom());
        }
    }, [centerPosition, map]);

    return null;
};

export default MapEffect;
