import React, { useCallback } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
    ChevronDownSquareCornersIcon24,
    SortDashesIcon24,
    SortDashesIcon32,
} from "@/components/icons";

import { FiltersList } from "@/enums/dashboard";

import useMediaQuery from "@/utils/hooks/use-media-query";

import { SortingFilterEnum } from "./sorting-filters.types";
import type { SortingFiltersProps } from "./sorting-filters.types";

import { PopUp, PopUpItem, SortingFiltersWrapperDiv, TextDiv } from "./sorting-filters.styles";

const SortingFilters = ({
    isOpen,
    handleOnClick,
    onSortingChange,
    sortingOption,
}: SortingFiltersProps) => {
    const history = useHistory();
    const isMobileAndTablet = useMediaQuery("(max-width: 1023px)");

    const sortingOptionText =
        sortingOption === SortingFilterEnum.LeastRecent
            ? "sorting-filters:least-recent"
            : "sorting-filters:most-recent";

    const onClose = useCallback(() => {
        if (!isOpen) return;
        handleOnClick(FiltersList.None);
    }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: onClose,
    });

    const handleToggleList = useCallback(
        (event: any) => {
            event.stopPropagation();
            handleOnClick(isOpen ? null : FiltersList.OrderBy);
        },
        [isOpen, handleOnClick],
    );

    const handleOptionClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const sortingOptionClicked = event.currentTarget.id; //ascending or descending
            event.stopPropagation();
            onSortingChange(sortingOptionClicked);
            handleOnClick(null);

            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("orderBy", sortingOptionClicked);
            history.push({ search: queryParams.toString() });
        },
        [onSortingChange],
    );

    return (
        <>
            <SortingFiltersWrapperDiv
                onClick={handleToggleList}
                ref={clickOutsideRef}
                isOpen={isOpen}
                data-test-id="recent-dd"
            >
                {isMobileAndTablet && <SortDashesIcon32 />}
                {!isMobileAndTablet && (
                    <>
                        <SortDashesIcon24 />
                        <TextDiv>
                            <FormattedMessage id={sortingOptionText} />
                        </TextDiv>
                        <ChevronDownSquareCornersIcon24 />
                    </>
                )}
            </SortingFiltersWrapperDiv>
            {isOpen && (
                <PopUp width={"174.95px"}>
                    <PopUpItem
                        id="descending"
                        isSelected={sortingOption === SortingFilterEnum.MostRecent}
                        onClick={handleOptionClick}
                    >
                        <FormattedMessage id="sorting-filters:most-recent" />
                    </PopUpItem>
                    <PopUpItem
                        id="ascending"
                        isSelected={sortingOption === SortingFilterEnum.LeastRecent}
                        onClick={handleOptionClick}
                    >
                        <FormattedMessage id="sorting-filters:least-recent" />
                    </PopUpItem>
                </PopUp>
            )}
        </>
    );
};

export default SortingFilters;
