import { TableCell } from "@mui/material";

import { DotsVerticalIcon32 } from "@/components/icons";
import DropdownMenu from "@/components/ui/dropdown-menu/dropdown-menu";

import { Roles } from "@/enums/user";

import type { UsersTableRowProps } from "./users-table-row.types";

import {
    DefaultTableCellContent,
    DotsIconWrapper,
    EmailTableCellContent,
    NameAndEmailWrapper,
    NameTableCell,
    NameTableCellContent,
    StyledAvatar,
    StyledTableRow,
} from "./users-table-row.styles";

const UsersTableRow = ({
    user,
    lastUserInRef,
    role,
    status,
    dateAdded,
    activeDropdownIndex,
    setActiveDropdownIndex,
    index,
    clickedItem,
    handleToggleDropdown,
    dropdownMenuOptions,
    handleDropdownMenuOptionClick,
}: UsersTableRowProps) => {
    const isAdminRole = role === Roles.Admin;

    return (
        <StyledTableRow key={user.userId} ref={lastUserInRef}>
            <TableCell sx={{ padding: "1rem 2rem 1rem 1rem" }} component="th" scope="row">
                <NameTableCell>
                    <StyledAvatar editable={false} name={user.displayName} size="large" />
                    <NameAndEmailWrapper>
                        <NameTableCellContent>{user.displayName}</NameTableCellContent>

                        <EmailTableCellContent>{user.email}</EmailTableCellContent>
                    </NameAndEmailWrapper>
                </NameTableCell>
            </TableCell>

            <TableCell sx={{ padding: "1rem" }} align="left">
                <DefaultTableCellContent>{role}</DefaultTableCellContent>
            </TableCell>

            <TableCell sx={{ padding: "1rem" }} align="left">
                <DefaultTableCellContent>{status}</DefaultTableCellContent>
            </TableCell>

            <TableCell sx={{ padding: "1rem" }} align="left">
                <DefaultTableCellContent>{dateAdded}</DefaultTableCellContent>
            </TableCell>

            <TableCell sx={{ padding: "1rem" }} align="center">
                <DefaultTableCellContent>
                    {!isAdminRole && (
                        <>
                            <DotsIconWrapper
                                isOpen={activeDropdownIndex === index}
                                onClick={() => handleToggleDropdown(index)}
                            >
                                <DotsVerticalIcon32 />
                                <DropdownMenu
                                    isOpen={activeDropdownIndex === index}
                                    items={dropdownMenuOptions}
                                    top={0}
                                    right={48}
                                    clickedItem={clickedItem}
                                    handleOptionClick={(item) =>
                                        handleDropdownMenuOptionClick(item, user)
                                    }
                                    setIsOpen={setActiveDropdownIndex}
                                />
                            </DotsIconWrapper>
                        </>
                    )}
                </DefaultTableCellContent>
            </TableCell>
        </StyledTableRow>
    );
};

export default UsersTableRow;
