import { CircuitTopStat } from "./circuit-top-stat";
import { CircuitStatWrapper } from "./circuit-top-stats-styles";
import { data } from "./data";

const CircuitTopStats = () => {
    const circuitStatsData = data?.sort((stat1: any, stat2: any) => stat1.order - stat2.order);

    return (
        <CircuitStatWrapper>
            {circuitStatsData.map((stat: any, index: number) => (
                <CircuitTopStat {...stat} key={`${stat.code}-${index}`} />
            ))}
        </CircuitStatWrapper>
    );
};

export default CircuitTopStats;
