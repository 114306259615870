import type { Session } from "@/types/sessions";

export enum SortingFilterEnum {
    MostRecent = "descending",
    LeastRecent = "ascending",
}

export interface SortingFiltersProps {
    isOpen: boolean;
    handleOnClick: (name: string | null) => void;
    onSortingChange: (sortingOption: string | null) => void;
    sortingOption: string | null;
}

export interface SortingPopUpProps {
    onSortingChange: (sessionList: Session[] | null) => void;
}
