import { useState } from "react";

import { useUser } from "@/context/user";

import DateFilter from "@/components/filters/date";
import SortingFilters from "@/components/filters/sorting-filters";
import TraineesFilter from "@/components/filters/trainees";

import { FiltersList } from "@/enums/dashboard";
import { Roles } from "@/enums/user";

import type { FiltersProps } from "./filters.types";

import { FiltersContainer } from "./filters.styles";

const Filters = ({
    onUserSelected,
    onDateChange,
    initialUserId,
    initialDateRange,
    dateRange,
    onSortingChange,
    sortingOption,
}: FiltersProps) => {
    const {
        state: { role },
    } = useUser();

    const isAdminOrInstructor = role === Roles.Instructor || role === Roles.Admin;

    const [filterOpen, setFilterOpen] = useState(FiltersList.None);

    return (
        <FiltersContainer>
            {isAdminOrInstructor && (
                <TraineesFilter
                    isOpen={FiltersList.TraineesList === filterOpen}
                    handleOnClick={(val: any) => setFilterOpen(val)}
                    onUserSelected={onUserSelected}
                    initialUserId={initialUserId}
                />
            )}
            <DateFilter
                isOpen={FiltersList.DatePicker === filterOpen}
                handleOnClick={(val: any) => setFilterOpen(val)}
                onChange={onDateChange}
                initialDateRange={initialDateRange}
                dateRange={dateRange}
            />
            <SortingFilters
                isOpen={FiltersList.OrderBy === filterOpen}
                handleOnClick={(val: any) => setFilterOpen(val)}
                onSortingChange={onSortingChange}
                sortingOption={sortingOption}
            />
        </FiltersContainer>
    );
};

export default Filters;
