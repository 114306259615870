import styled from "styled-components";

import base from "@/themes/base/base";

import { Item, ListContainer, Toggle } from "../filters.styles";

export const SelectAllContainer = styled.div<{ isChecked: boolean }>`
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    height: 2.5rem;
    box-sizing: border-box;
    position: relative;

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

        .styled-checkbox {
            border-color: ${({ theme }) => theme.colors.primary.orange[500]};
            background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

            &.checked {
                border-color: ${({ theme }) => theme.colors.primary.orange[300]};
                background-color: ${({ theme }) => theme.colors.primary.orange[300]};
            }
        }
    }
`;

export const ToggleButton = styled(Toggle)<{ isOpen: boolean }>`
    max-width: 100%;
    width: 100%;
    border: 1px solid
        ${({ isOpen, theme }) =>
            isOpen ? theme.colors.primary.orange[500] : theme.colors.neutral.grey[800]};

    &:hover {
        ${base.colors.neutral.grey[600]};
    }
`;

export const SelectedCountSpan = styled.span`
    border-radius: 32px;
    background-color: ${({ theme }) => theme.colors.primary.orange[500]};
    padding: 0px 8px;
    color: ${({ theme }) => theme.colors.neutral.grey[900]};
`;

export const ToggleButtonContent = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
`;

export const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
    width: 2rem;
    display: flex;
    justify-content: flex-end;
    transform: ${({ isOpen }) => (isOpen ? "scaleY(-1)" : "scaleY(1)")};
    transition: transform 0.3s ease;
`;

export const CheckboxContainer = styled.div`
    margin-right: 8px;
`;

export const StyledItem = styled(Item)<{ isChecked: boolean }>`
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
    background-color: ${({ isChecked, theme }) =>
        isChecked ? theme.colors.neutral.grey[800] : "transparent"};

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

        .styled-checkbox {
            border-color: ${({ theme }) => theme.colors.primary.orange[500]};
            background-color: ${({ theme }) => theme.colors.neutral.grey[600]};

            &.checked {
                border-color: ${({ theme }) => theme.colors.primary.orange[300]};
                background-color: ${({ theme }) => theme.colors.primary.orange[300]};
            }
        }
    }
`;

export const SeparatorLine = styled.div`
    width: 80%;
    background-color: #121212;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`;

export const TextSpan = styled.span`
    ${base.typography.smallRegular};

    &.checked {
        ${base.typography.smallMedium};
    }
`;

export const MultiSelectListContainer = styled(ListContainer)`
    max-height: 11.375rem;
`;
