import React, { createContext, useContext } from "react";
import type { ReactNode } from "react";
import { useQuery } from "react-query";

import { getCombinedCharts, getCustomCharts } from "@/api/sessions";
import { getCustomerSettings } from "@/api/settings";

import useQueryParams from "@/utils/use-query-params";

//context type
interface SessionDetailsType {
    //CombinedChartsData
    isFetchingCombinedCharts: boolean;
    combinedChartsData: any;
    isErrorCombinedCharts: boolean;
    //CustomChartsData
    isFetchingCustomCharts: boolean;
    customChartsData: any;
    isErrorCustomCharts: boolean;
    //CustomerSettingsData
    isFetchingCustomerSettings: boolean;
    customerSettingsData: any;
    isErrorCustomerSettings: boolean;
}

interface CombinedChartsDataProps {
    combinedChartsData: any;
    isFetching: boolean;
    customerName?: string;
}

interface CustomChartsDataProps {
    customChartsData: any;
    isFetching: boolean;
}

interface CustomerSettingsDataProps {
    customerName: string;
    emailProvider: string;
    scenarioImageUrl: string;
    simLogoUrl: string;
}

//create the context
const SessionDetailsContext = createContext<SessionDetailsType | undefined>(undefined);

//create the provider
export const SessionDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const params: any = useQueryParams();
    const userId = params.get("userId");
    const from: string = params.get("from");
    const to: string = params.get("to");
    const simulationInstanceId: string = params.get("simulationInstanceId");
    const scenarioInstanceId: string = params.get("scenarioInstanceId");

    //CombinedChartsData
    //API request: /combined-custom-charts
    //EyeTracking, CockpitTimeline, TimelineChartDriving & TimelineChartFiring components
    //TimelineChartFlight uses a separate API call to CombinedCharts (through usePoll function)
    const {
        isFetching: isFetchingCombinedCharts,
        data: combinedChartsData,
        isError: isErrorCombinedCharts,
    } = useQuery<CombinedChartsDataProps>(
        ["getCombinedCharts", from, to, userId, simulationInstanceId, scenarioInstanceId],
        () => getCombinedCharts({ from, to, userId, simulationInstanceId, scenarioInstanceId }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true,
        },
    );

    //CustomChartsData
    //API request: /custom-charts
    //CognitiveLoad(SemiCircularGauge), FireMission & OverallScore components
    //LandingScore component uses a separate API call to CustomChartsData (through usePoll function)
    const {
        isFetching: isFetchingCustomCharts,
        data: customChartsData,
        isError: isErrorCustomCharts,
    } = useQuery<CustomChartsDataProps>(
        ["getCustomCharts", from, to, userId, simulationInstanceId, scenarioInstanceId],
        () => getCustomCharts({ from, to, userId, simulationInstanceId, scenarioInstanceId }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true,
        },
    );

    // CustomerSettingsData
    // API request: /settings/customer-settings
    const {
        isFetching: isFetchingCustomerSettings,
        data: customerSettingsData,
        isError: isErrorCustomerSettings,
    } = useQuery<CustomerSettingsDataProps>(["getCustomerSettings"], getCustomerSettings, {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: false,
        enabled: true,
    });

    return (
        <SessionDetailsContext.Provider
            value={{
                //CombinedChartsData
                isFetchingCombinedCharts,
                combinedChartsData,
                isErrorCombinedCharts,
                //CustomChartsData
                isFetchingCustomCharts,
                customChartsData,
                isErrorCustomCharts,
                // CustomerSettingsData
                isFetchingCustomerSettings,
                customerSettingsData,
                isErrorCustomerSettings,
            }}
        >
            {children}
        </SessionDetailsContext.Provider>
    );
};

//custom hook to export context's values & functions
export const useSessionDetails = (): SessionDetailsType => {
    const context = useContext(SessionDetailsContext);

    if (!context) {
        throw new Error("useSessionDetails hook must be used within a ModalProvider");
    }

    return context;
};
