import styled from "styled-components";

export const StyledGridLayoutItem = styled.div`
    /* we need the width of the components to be 98.8%,
  because the parentRef div doest not account for width of the scrollbar */
    width: 98.8%;
`;

export const GridLayoutItem = styled.div`
    width: 100%;
`;
