import type { ReactNode } from "react";
import React from "react";

import Loader from "@/components/ui/loader";

import {
    PageHeaderWrapperDiv,
    LogoImage,
    LoaderContainer,
    StyledTitleText,
    StyledUserInfoText,
    TitleDetailsWrapperDiv,
} from "./page-header.styles";

interface PageHeaderProps {
    isLoading?: boolean;
    logoUrl?: string;
    currentPage?: any;
    title?: string;
    subtitleDetailText?:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | React.ReactPortal
        | null;
}

const PageHeader = ({
    isLoading,
    logoUrl,
    currentPage,
    title,
    subtitleDetailText,
}: PageHeaderProps) => {
    return (
        <PageHeaderWrapperDiv>
            {isLoading ? (
                <LoaderContainer>
                    <Loader size="medium" />
                </LoaderContainer>
            ) : (
                <LogoImage src={logoUrl} />
            )}

            <TitleDetailsWrapperDiv>
                <StyledTitleText>{currentPage ? currentPage.label : title}</StyledTitleText>
                <StyledUserInfoText>{subtitleDetailText}</StyledUserInfoText>
            </TitleDetailsWrapperDiv>
        </PageHeaderWrapperDiv>
    );
};

export default PageHeader;
