import React from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

import { updateUserRole } from "@/api/users";

import { useModal } from "@/context/modal/modal";

import GenericError from "@/components/ui/generic-error";

import type { User } from "@/types/users";

import { Roles } from "@/enums/user";

import {
    ModalContentWrapperDiv,
    Title,
    Description,
    ButtonsWrapper,
    StyledCancelButton,
    StyledConfirmButton,
} from "../../modal-contents.styles";

import ConfirmationModalContent from "../confirmation/confirmation-modal-content";

interface ChangeRoleModalContentProps {
    user: User;
    setShouldUpdateUsersList: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeRoleModalContent = ({
    user,
    setShouldUpdateUsersList,
}: ChangeRoleModalContentProps) => {
    const { closeModal, openModal } = useModal();

    const queryClient = useQueryClient();

    const userId = user.userId;

    const currentRole = user.roles.includes(Roles.Instructor) ? Roles.Instructor : Roles.Trainee;

    const newRole = currentRole === Roles.Instructor ? Roles.Trainee : Roles.Instructor;

    const mutation = useMutation(updateUserRole, {
        onSuccess: () => {
            const queryKey = ["getUsers", null, null];

            queryClient.invalidateQueries(queryKey); // Invalidates cached data associated with the "getUsers" query key

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(true);
            }

            openModal({
                content: (
                    <ConfirmationModalContent title="users:users-table:modal-contents:change-role:confirmation-modal:title" />
                ),
            });

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(false);
            }
        },
        onError: () => {
            openModal({ content: <GenericError /> });
        },
    });

    const handleChangeRoleButton = () => {
        mutation.mutate({ userId, newRole }); //PUT request to change role -> /User?Role API { userId: string, newRole: string}
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id="users:users-table:modal-contents:change-role:title" />
            </Title>
            <Description>
                <FormattedMessage id={"users:users-table:modal-contents:change-role:description"} />
            </Description>
            <ButtonsWrapper>
                <StyledConfirmButton onClick={handleChangeRoleButton} fullWidth>
                    <FormattedMessage id="users:users-table:modal-contents:change-role:primary-button" />
                </StyledConfirmButton>
                <StyledCancelButton onClick={closeModal} fullWidth>
                    <FormattedMessage id="users:users-table:modal-contents:secondary-button" />
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default ChangeRoleModalContent;
