import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useSessionDetails } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import { StyledRadioButton } from "@/components/ui/styled-radio";
import Tooltip from "@/components/ui/tooltip/tooltip";

import type { ChartData, ChartProps, ChartState } from "@/types/timeline-chart";

import {
    Input,
    Label,
    RadioWrapper,
    Title,
    TitleAndRadioWrapper,
} from "../../session-detail.styles";

import CombinedChart from "./combined-chart-firing";
import { Container } from "./styles";

const TimelineChartFiring = () => {
    const { combinedChartsData, isFetchingCombinedCharts } = useSessionDetails();

    const timelineDataSets: any = useMemo(
        () => combinedChartsData.filter((dataSet: any) => dataSet.chartType === "Timeline"),
        [combinedChartsData],
    );
    const timelineChartData: ChartData[] = timelineDataSets.flatMap((chart: any) => chart.datasets);

    const areaChartData: ChartData[] = useMemo(
        () => timelineChartData.filter((chart: ChartData) => chart.type === "area"),
        [timelineChartData],
    );
    const scatterChartData: ChartData[] = useMemo(
        () => timelineChartData.filter((chart: ChartData) => chart.type === "scatter"),
        [timelineChartData],
    );

    const sortedDataForRadio = timelineChartData.sort(
        (a: ChartData, b: ChartData) => a.position - b.position,
    );
    const chartTypes: ChartProps = sortedDataForRadio.reduce((acc: any, obj: any) => {
        acc[obj.name] = {
            label: obj.radioLabel,
            color: obj.color,
            position: obj.position,
            disabled: obj.data.length === 0 ? true : false,
        };

        return acc;
    }, {});

    //setting the state for each chart for toggle
    const chartState: ChartState = {};

    for (const key in chartTypes) {
        chartState[key] = true;
    }

    const [chartStateByType, setChartStateByType] = useState<ChartState>(chartState);

    const changeHandler = (chartType: string) => {
        setChartStateByType((prevState) => ({
            ...prevState,
            [chartType]: !prevState[chartType],
        }));
    };

    if (isFetchingCombinedCharts) return <ContentLoader height="23rem" />;

    //if area chart data is empty, do not load component
    if (areaChartData.length === 0) return null;

    return (
        <Container>
            <TitleAndRadioWrapper>
                <Title>
                    <FormattedMessage id={"session-detail:timeline"} />
                    <Tooltip simpleText={timelineDataSets?.[0]?.description} />
                </Title>

                <RadioWrapper>
                    {Object.entries(chartTypes).map(([chartType, chartProps]) => {
                        const classNames = `
              ${chartStateByType[chartType] ? "" : "unchecked"} 
              ${chartProps?.disabled ? "disabled" : ""}`.trim();

                        const testId = chartProps?.label.toLowerCase().replace(/\s+/g, "-");

                        return (
                            <Label
                                key={chartType}
                                htmlFor={chartType}
                                className={classNames}
                                data-test-id={testId}
                            >
                                <Input
                                    type="checkbox"
                                    defaultChecked
                                    id={chartType}
                                    onChange={() => changeHandler(chartType)}
                                    disabled={chartProps?.disabled}
                                />
                                <StyledRadioButton
                                    selected={!!chartStateByType[chartType]}
                                    color={chartProps?.color}
                                />
                                {chartProps?.label}
                            </Label>
                        );
                    })}
                </RadioWrapper>
            </TitleAndRadioWrapper>

            <CombinedChart
                areaChartData={areaChartData}
                scatterChartData={scatterChartData}
                chartStateByType={chartStateByType}
            />
        </Container>
    );
};

export default TimelineChartFiring;
