import { useEffect } from "react";
import type { ReactNode } from "react";

import { CloseIcon12 } from "@/components/icons";

import { CloseButton, Container, Wrapper } from "./modal.styles";

type Props = {
    children: ReactNode;
    padding?: string | number;
    closeDisabled: boolean;
    onCloseClick?: () => void;
    width?: string | number;
    height?: string | number;
    transparent?: boolean;
    overlayOpacity?: number;
};

const Modal = ({
    children,
    padding = "1rem",
    closeDisabled = false,
    onCloseClick = () => {},
    width = "70%",
    height = "80%",
    transparent = false,
    overlayOpacity = 0.8,
    ...remainingProps
}: Props) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "initial";
        };
    }, []);

    return (
        <Wrapper opacity={overlayOpacity} {...remainingProps}>
            <Container
                width={width}
                height={height}
                padding={padding}
                hasHeader={!closeDisabled}
                transparent={transparent}
            >
                {children}
                {!closeDisabled && onCloseClick && (
                    <CloseButton onClick={onCloseClick}>
                        <CloseIcon12 />
                    </CloseButton>
                )}
            </Container>
        </Wrapper>
    );
};

export default Modal;
