export const Headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Sim Name", key: "simName" },
    { label: "Scenario Name", key: "scenarioName" },
    { label: "Session Start", key: "sessionStart" },
    { label: "Session End", key: "sessionEnd" },
];

export const transformData = (data: any) => {
    const csvData: any = [];
    data?.map((dataSet: any) => {
        csvData.push({
            sessionStart: dataSet.sessions[0].from,
            sessionEnd: dataSet.sessions[0].to,
            simName: dataSet.simulationName,
            scenarioName: dataSet.sessions[0].scenarioName,
            name: dataSet.name,
            email: dataSet.email,
        });
    });

    return csvData;
};
