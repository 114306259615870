import React from "react";

import Tooltip from "@/components/ui/tooltip/tooltip";

import type { CircuitTopStatProps, StatsDataSet, Value } from "@/types/session-details";

import { Title, Divider } from "../../session-detail.styles";

import { Section } from "./circuit-top-stats-styles";
import { arrayOfTwoDatasets } from "./utils";

export const CircuitTopStat = ({ name, description, dataSets }: CircuitTopStatProps) => {
    const dataSetChunks = arrayOfTwoDatasets(dataSets);

    return (
        <Section.Container>
            <Title>
                {name}
                <Tooltip simpleText={description} />
            </Title>

            <Section.SubContainer>
                {dataSetChunks.map((dataSet: StatsDataSet[], ind: number) => (
                    <Section.RowWrapper key={ind}>
                        {dataSet.map((stat: StatsDataSet, index: number) => (
                            <React.Fragment key={`${stat.title}-${index}`}>
                                <Section.Wrapper>
                                    <Section.StatTitle>{stat?.title}</Section.StatTitle>
                                    {stat?.subTitle && (
                                        <Section.StatSubTitle>{stat.subTitle}</Section.StatSubTitle>
                                    )}

                                    <Section.StatWrapper>
                                        {stat.values.map((value: Value) => {
                                            stat.values.sort((a: any, b: any) => a.order - b.order);

                                            return (
                                                <Section.StatValueWrapper key={value.result}>
                                                    <Section.StatValue>
                                                        {value?.result}
                                                    </Section.StatValue>
                                                    {value?.description && (
                                                        <Section.StatValueDescription>
                                                            {value.description}
                                                        </Section.StatValueDescription>
                                                    )}
                                                </Section.StatValueWrapper>
                                            );
                                        })}
                                    </Section.StatWrapper>
                                </Section.Wrapper>

                                {index < dataSet.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </Section.RowWrapper>
                ))}
            </Section.SubContainer>
        </Section.Container>
    );
};
